<template>
  <teleport to="body">
    <div v-if="show" @click="$emit('close')"></div>
    <transition name="dialog">
      <dialog open v-if="show" :class="{ addWidth: width }">
        <header v-if="title" class="text-center">
          <slot name="header">
            <h5>{{ title }}</h5>
          </slot>
        </header>
        <section>
          <slot></slot>
        </section>
      </dialog>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    color: {
      type: String,
    },
    width: {
      required: false,
    },
    show: {
      required: true,
    },
  },
  emits: ["close"],
};
</script>

<style scoped>
div {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 200;
}
dialog {
  /* position: absolute; */
  position: fixed;
  top: 12vh;
  min-width: 300px;
  max-width: 700px;
  z-index: 1000;
  border-radius: 0.1rem;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0.8rem;
  margin: 0 auto;
}
dialog.addWidth {
  min-width: 560px;
}
header {
  position: relative;
  background: #0057b7;
  color: #fff;
  width: 100%;
  padding: 1rem;
}

header h2 {
  margin: 0;
}
header span {
  position: absolute;
  top: 10%;
  right: 5%;
  font-size: 24px;
  cursor: pointer;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}
.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}
.dialog-enter-active,
.dialog-leave-active {
  transition: all 0.35s ease;
}
@media (max-width: 768px) {
  dialog {
    top: 3vh;
    width: 20rem;
  }
}
@media (max-width: 578px) {
  dialog.addWidth,
  dialog {
    min-width: 95%;
  }
}
@media screen and (max-width: 370px) {
  dialog.addWidth,
  dialog {
    min-width: 98%;
  }
}
@media screen and (max-height: 780px) {
  dialog.addWidth,
  dialog {
    top: 1vh;
  }
}
</style>
